import React from 'react'
import { FundOutlined } from '@ant-design/icons'
import { Row, Col, Card, Typography } from 'antd'
import { NextLink, getChildrenToRender } from 'shared/util'

const { Text } = Typography

const dataSource = () => {
  return {
    wrapper: { className: 'home-page-wrapper commentSection-wrapper' },
    page: { className: 'home-page commentSection' },
    OverPack: { playScale: 0.3 },
    titleWrapper: {
      className: 'commentSection-title-wrapper',
      children: [
        {
          name: 'title',
          className: 'commentSection-title-h1',
          children: 'Latest IPOs',
        },
      ],
    },
  }
}

function IpoSection(props) {
  const { ipos } = props
  const dataSourceWithBrokers = dataSource()
  const { titleWrapper } = dataSourceWithBrokers
  return (
    <div {...dataSourceWithBrokers.wrapper}>
      <div {...dataSourceWithBrokers.page}>
        <div {...dataSourceWithBrokers.titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <Row gutter={[150, 56]}>
          {ipos.map((item, i) => (
            <Col xs={24} lg={12} key={i}>
              <Card
                key={i}
                headStyle={{ background: '#E6F7FF' }}
                title={
                  <>
                    <FundOutlined /> {item.attributes.name}
                  </>
                }
                type="inner"
                style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              >
                <div>
                  <Text>{item.attributes.description?.slice(0, 120)}</Text>
                </div>
                <div style={{ float: 'right' }}>
                  <NextLink href={`/ipos/${item.attributes.slug}`}>
                    {item.attributes.name} IPO Details
                  </NextLink>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default IpoSection
