/* eslint no-undef: 0 */

/* eslint arrow-parens: 0 */
import Head from 'next/head'
import { useRouter } from 'next/router'
import AboveFooter from 'components/home/AboveFooter'
import Banner3 from 'components/home/Banner3'
import CommentsSection from 'components/home/CommentsSection'
import IpoSection from 'components/home/IposSection'
import PopularQuestions from 'components/home/PopularQuestions'
import Banner30DataSource from 'components/home/data.source'
import {
  HomeContentDocument,
  HomeContentCanadaDocument,
} from 'graphql/generated/types'
import ApplicationLayout from 'layouts/application'
import PropTypes from 'prop-types'
import { initializeApollo, addApolloState } from 'util/apolloClient'

const description = () =>
  `A Personal Finance FAQ portal for India, Canada & USA investors. Financial education hub sharing broker
reviews, stock market queries, trading & investment software and apps.`

const Home = (props) => {
  const { isMobile, data } = props
  const router = useRouter()
  const { country_slug } = router.query
  const {
    all_brokers: allBrokers,
    latest_comments: LatestComments,
    all_pages: { data: allPages },
    latest_ipos: { data: LatestIpos } = { data: [] },
  } = data

  return (
    <ApplicationLayout
      allBrokers={allBrokers.data}
      navCalculators={[]}
      allPages={allPages}
    >
      <Head>
        <title>Finance Education Hub: Financial FAQs - Fintrakk</title>
        <meta name="description" content={description()} />
      </Head>
      <div className="templates-wrapper">
        <Banner3
          id="Banner3_0"
          key="Banner3_0"
          dataSource={Banner30DataSource}
          isMobile={isMobile}
        />
        <PopularQuestions
          id="Feature7_0"
          key="Feature7_0"
          isMobile={isMobile}
          articles={data}
        />
        {country_slug === 'us' && LatestIpos.length > 0 && (
          <IpoSection
            id="comments_section"
            key="comments_section"
            isMobile={isMobile}
            ipos={LatestIpos}
          />
        )}
        {LatestComments.length > 0 && (
          <CommentsSection
            id="comments_section"
            key="comments_section"
            isMobile={isMobile}
            comments={LatestComments}
          />
        )}
        <AboveFooter id="Feature7_0" key="Feature7_0" isMobile={isMobile} />,
      </div>
    </ApplicationLayout>
  )
}

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export async function getServerSideProps({ params }) {
  const apolloClient = initializeApollo()
  const { country_slug } = params
  const queryResults = await apolloClient.query({
    query:
      country_slug === 'ca' ? HomeContentCanadaDocument : HomeContentDocument,
    variables: { country: country_slug },
  })

  const { data } = queryResults
  return addApolloState(apolloClient, {
    props: { data },
  })
}

export default Home
